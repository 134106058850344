<template>
  <app-page-layout :title="$t('hr.travel.request_travel')" icon="tw-hr-cost" @close="leavePage">
    <template #content>
      <validation-observer ref="observer">
        <v-row>
          <v-col cols="12">
            <validation-provider :name="$t('hr.travel.leave_request')" rules="required" v-slot="{ errors }">
              <leave-request-picker v-model="leaveRequest" return-object :error-messages="errors" />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6">
            <validation-provider :name="$t('hr.travel.begin_date')" rules="required" v-slot="{ errors }">
              <date-time-picker
                v-model="travel.beginDate"
                :max="travel.endDate || maxEndDate"
                :min="minBeginDate"
                :label="$t('hr.travel.begin_date')"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6">
            <validation-provider :name="$t('hr.travel.end_date')" rules="required" v-slot="{ errors }">
              <date-time-picker
                v-model="travel.endDate"
                :min="travel.beginDate || minBeginDate"
                :max="maxEndDate"
                :label="$t('hr.travel.end_date')"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <validation-provider :name="$t('hr.travel.start_work_date')" rules="required" v-slot="{ errors }">
              <date-picker
                v-model="travel.startWorkDate"
                :min="travel.endDate || maxEndDate"
                :label="$t('hr.travel.start_work_date')"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <v-switch v-model="travel.isDomestic" color="teal" :label="$t('hr.travel.is_domestic')" />
          </v-col>
          <v-col cols="12">
            <validation-provider :name="$t('global.address')" rules="required|max:512" v-slot="{ errors }">
              <address-picker v-model="travel.address" :error-messages="errors" @change="onAddressChange" />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="4">
            <validation-provider :name="$t('hr.travel.country')" rules="required|max:255" v-slot="{ errors }">
              <v-text-field
                v-model.trim="travel.country"
                :label="$t('hr.travel.country')"
                :counter="255"
                color="tertiary"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="4">
            <validation-provider :name="$t('hr.travel.city')" rules="required|max:255" v-slot="{ errors }">
              <v-text-field
                v-model.trim="travel.city"
                :label="$t('hr.travel.city')"
                :counter="255"
                color="tertiary"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="4">
            <validation-provider :name="$t('hr.travel.region')" rules="required|max:255" v-slot="{ errors }">
              <v-text-field
                v-model.trim="travel.region"
                :label="$t('hr.travel.region')"
                :counter="255"
                color="tertiary"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <validation-provider :name="$t('hr.travel.description')" rules="required|max:500" v-slot="{ errors }">
              <v-textarea
                v-model.trim="travel.travelDescription"
                rows="1"
                :label="$t('hr.travel.description')"
                :counter="500"
                color="tertiary"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <validation-provider :name="$t('hr.travel.reason')" rules="required|max:500" v-slot="{ errors }">
              <v-textarea
                v-model.trim="travel.reason"
                rows="1"
                :label="$t('hr.travel.reason')"
                :counter="500"
                color="tertiary"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <project-picker v-model="travel.project" />
          </v-col>
          <v-col cols="12">
            <travel-details
              v-model="travel.travelDetails"
              :min-date="travel.beginDate || minBeginDate"
              :max-date="travel.endDate || maxEndDate"
              ref="travelDetails"
            />
          </v-col>
        </v-row>
      </validation-observer>
    </template>
    <template #footer>
      <app-page-footer create :disabled="$helpers.get($refs, 'travelDetails.disableCreateButton', false)">
        <template #right>
          <v-btn text @click="validateAndSave" :loading="loading">{{ $t("buttons.save") }}</v-btn>
        </template>
      </app-page-footer>
    </template>
  </app-page-layout>
</template>

<script>
  import Travel from "../model/Travel";

  export default {
    name: "TravelCreate",
    components: {
      AppPageFooter: () => import("@/layouts/app-page-footer"),
      LeaveRequestPicker: () => import("@/components/selectpickers/LeaveRequestPicker"),
      ProjectPicker: () => import("@/components/selectpickers/ProjectPicker"),
      TravelDetails: () => import("../components/TravelDetails")
    },
    data: () => ({
      loading: false,
      travel: new Travel(),
      leaveRequest: null
    }),
    computed: {
      minBeginDate() {
        return this.leaveRequest
          ? this.$helpers.get(this.leaveRequest, "beginLeaveRequestDay.date") + "T00:00:00Z"
          : null;
      },
      maxEndDate() {
        return this.leaveRequest
          ? this.$helpers.get(this.leaveRequest, "endLeaveRequestDay.date") + "T23:59:00Z"
          : null;
      }
    },
    watch: {
      leaveRequest(leaveRequest) {
        if (leaveRequest) {
          this.travel.leaveRequest = this.$helpers.get(leaveRequest, "id");
          this.travel.beginDate = this.$helpers.get(leaveRequest, "beginLeaveRequestDay.date") + "T00:00:00Z";
          this.travel.endDate = this.$helpers.get(leaveRequest, "endLeaveRequestDay.date") + "T23:59:00Z";
        }
      }
    },
    methods: {
      onAddressChange(address) {
        if (address) {
          this.travel.country = address.country;
          this.travel.city = address.administrative_area_level_1 || address.locality;
          this.travel.region =
            address.administrative_area_level_2 ||
            address.administrative_area_level_3 ||
            address.administrative_area_level_4;
        }
      },
      leavePage() {
        this.$router.replace({ name: "travels" });
      },
      validateDetails() {
        return Promise.all(
          this.$refs.travelDetails.value.map((item, index) =>
            this.$refs.travelDetails.$refs[`travelDetailItem${index}`][0].$refs.observer.validate()
          )
        );
      },
      validateAndSave() {
        this.$refs.observer.validate().then(valid => {
          this.validateDetails().then(valids => {
            if (valid && !valids.some(item => item === false)) {
              this.loading = true;
              this.$api.travelService
                .save(this.travel)
                .then(({ data }) => {
                  if (!data.error) {
                    this.$emit("success:save");
                    this.leavePage();
                  }
                })
                .catch(e => {
                  this.$helpers.showNotification(e.message);
                })
                .finally(() => {
                  this.loading = false;
                });
            }
          });
        });
      }
    }
  };
</script>

<style scoped></style>
