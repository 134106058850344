import store from "@/store";

export default class Travel {
  constructor() {
    this.id = null;
    this.employee = store.state.auth.user.id;
    this.leaveRequest = null;
    this.beginDate = null;
    this.endDate = null;
    this.startWorkDate = null;
    this.isDomestic = null;
    this.address = null;
    this.country = null;
    this.city = null;
    this.region = null;
    this.travelDescription = null;
    this.reason = null;
    this.travelDetails = [];
  }
}
